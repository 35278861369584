// src/components/DataCharts.js
import React, { useState, useMemo, useEffect } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  PieChart, Pie, Cell
} from 'recharts';
import { scaleLinear } from 'd3-scale';
import { rgb } from 'd3-color';

const BASE_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d', '#ffc658', '#8dd1e1', '#d0ed57', '#a4de6c'];

const DataCharts = ({ chartData }) => {
  const [selectedCountries, setSelectedCountries] = useState([]);

  const processedData = useMemo(() => {
	const mergedData = {};
	Object.entries(chartData).forEach(([datasetName, { data, unit, date_collected, source }]) => {
	  data.forEach(item => {
		if (!mergedData[item.name]) {
		  mergedData[item.name] = { name: item.name };
		}
		mergedData[item.name][datasetName] = {
		  value: typeof item.value === 'string' ? parseFloat(item.value.replace(/[^0-9.-]+/g, '')) : item.value,
		  unit,
		  date_collected,
		  source
		};
	  });
	});
	return Object.values(mergedData);
  }, [chartData]);

  useEffect(() => {
	// Initially select top 10 countries based on the first dataset
	const firstDataset = Object.keys(chartData)[0];
	if (firstDataset) {
	  const topCountries = processedData
		.filter(item => item[firstDataset])
		.sort((a, b) => b[firstDataset].value - a[firstDataset].value)
		.slice(0, 10)
		.map(item => item.name);
	  setSelectedCountries(topCountries);
	}
  }, [chartData, processedData]);

  const filteredData = useMemo(() => 
	processedData.filter(item => selectedCountries.includes(item.name)),
	[processedData, selectedCountries]
  );

  const handleCountryToggle = (country) => {
	setSelectedCountries(prev => 
	  prev.includes(country) ? prev.filter(c => c !== country) : [...prev, country]
	);
  };

  const getColor = (countryIndex, datasetIndex, totalDatasets) => {
	const baseColor = rgb(BASE_COLORS[countryIndex % BASE_COLORS.length]);
	const lightnessScale = scaleLinear()
	  .domain([0, totalDatasets - 1])
	  .range([baseColor.darker(1).formatHex(), baseColor.brighter(1).formatHex()]);
	return lightnessScale(datasetIndex);
  };

  const datasets = Object.keys(chartData);

  const barChartData = filteredData.map(country => ({
	name: country.name,
	...datasets.reduce((acc, dataset) => {
	  acc[dataset] = country[dataset]?.value || 0;
	  return acc;
	}, {})
  }));

  const pieChartData = filteredData.flatMap(country => 
	datasets.map(dataset => ({
	  name: `${country.name} - ${dataset}`,
	  value: country[dataset]?.value || 0,
	  country: country.name,
	  dataset,
	  unit: country[dataset]?.unit || '',
	  date_collected: country[dataset]?.date_collected || '',
	  source: country[dataset]?.source || ''
	}))
  );

  if (Object.keys(chartData).length === 0) {
	return <p>No data available for charts</p>;
  }

  return (
	<div className="data-charts">
	  
	  <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
		{/* Bar Chart */}
		<div style={{ width: '45%', height: 400, minWidth: 300 }}>
		  <h4>Selected Countries - Bar Chart</h4>
		  <ResponsiveContainer width="100%" height="100%">
			<BarChart data={barChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
			  <CartesianGrid strokeDasharray="3 3" />
			  <XAxis dataKey="name" angle={-45} textAnchor="end" height={100} />
			  <YAxis />
			  <Tooltip content={<CustomTooltip />} />
			  <Legend />
			  {datasets.map((datasetName, index) => (
				<Bar key={datasetName} dataKey={datasetName} name={datasetName} fill={BASE_COLORS[index % BASE_COLORS.length]} />
			  ))}
			</BarChart>
		  </ResponsiveContainer>
		</div>

		{/* Pie Chart */}
		<div style={{ width: '45%', height: 400, minWidth: 300 }}>
		  <h4>Selected Countries - Pie Chart</h4>
		  <ResponsiveContainer width="100%" height="100%">
			<PieChart>
			  <Pie
				data={pieChartData}
				dataKey="value"
				nameKey="name"
				cx="50%"
				cy="50%"
				outerRadius={130}
				fill="#8884d8"
				label={(entry) => entry.country}
			  >
				{pieChartData.map((entry, index) => (
				  <Cell 
					key={`cell-${index}`} 
					fill={getColor(
					  filteredData.findIndex(c => c.name === entry.country),
					  datasets.indexOf(entry.dataset),
					  datasets.length
					)}
				  />
				))}
			  </Pie>
			  <Tooltip content={<CustomTooltip />} />
			  
			</PieChart>
		  </ResponsiveContainer>
		</div>

		{/* Data Table */}
		<div style={{ width: '100%', marginTop: '20px' }}>
		  <h4>Countries - Data Table</h4>
		  <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
			<table style={{ width: '100%', borderCollapse: 'collapse' }}>
			  <thead>
				<tr>
				  <th style={tableHeaderStyle}>Country</th>
				  {datasets.map(datasetName => (
					<th key={datasetName} style={tableHeaderStyle}>{datasetName}</th>
				  ))}
				  <th style={tableHeaderStyle}>Select</th>
				</tr>
			  </thead>
			  <tbody>
				{processedData.map((country) => (
				  <tr key={country.name}>
					<td style={tableCellStyle}>{country.name}</td>
					{datasets.map(datasetName => (
					  <td key={datasetName} style={tableCellStyle}>
						{country[datasetName] 
						  ? `${country[datasetName].value.toLocaleString()} ${country[datasetName].unit}` 
						  : '-'}
					  </td>
					))}
					<td style={tableCellStyle}>
					  <input
						type="checkbox"
						checked={selectedCountries.includes(country.name)}
						onChange={() => handleCountryToggle(country.name)}
					  />
					</td>
				  </tr>
				))}
			  </tbody>
			</table>
		  </div>
		</div>
	  </div>
	</div>
  );
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
	const datasetInfo = payload[0].payload;
	return (
	  <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
		<p className="label">{`${label || 'N/A'}`}</p>
		{payload.map((pld, index) => (
		  <p key={index} style={{ color: pld.color }}>
			{`${pld.name}: ${pld.value.toLocaleString()} ${pld.payload?.unit || ''}`}
		  </p>
		))}
		<p>Date Collected: {datasetInfo.date_collected}</p>
		<p>Source: {datasetInfo.source}</p>
	  </div>
	);
  }
  return null;
};

const tableHeaderStyle = {
  backgroundColor: '#f2f2f2',
  padding: '10px',
  borderBottom: '1px solid #ddd',
  textAlign: 'left'
};

const tableCellStyle = {
  padding: '10px',
  borderBottom: '1px solid #ddd'
};

export default DataCharts;