// src/utils/datasetUtils.js
import Papa from 'papaparse';

/**
 * Fetches and processes the dataset list from index.json.
 * @returns {Promise<Array>} Array of dataset objects with additional metadata.
 */
export const getDatasetList = async () => {
  try {
	console.log('Fetching dataset list...');
	const response = await fetch('/datasets/index.json');
	console.log('Fetch response status:', response.status);
	if (!response.ok) {
	  throw new Error(`HTTP error! status: ${response.status}`);
	}
	
	const text = await response.text();
	console.log('Raw response text:', text);

	let data;
	try {
	  data = JSON.parse(text);
	} catch (parseError) {
	  console.error('Error parsing JSON:', parseError);
	  throw new Error(`Failed to parse JSON: ${parseError.message}. Raw text: ${text}`);
	}

	console.log('Parsed data:', data);

	if (!Array.isArray(data)) {
	  throw new Error(`Expected an array, but got: ${typeof data}. Content: ${JSON.stringify(data)}`);
	}

	const result = data.map(dataset => ({
	  value: dataset.filename,
	  label: dataset.filename.replace('.csv', '').replace(/-/g, ' '),
	  category: dataset.category,
	  date_collected: dataset.date_collected,
	  source: dataset.source,
	  filepath: dataset.filepath
	}));
	
	console.log('Processed dataset list:', result);
	return result;
  } catch (error) {
	console.error('Error in getDatasetList:', error);
	throw error;
  }
};

/**
 * Loads and parses CSV data from the given filepath.
 * @param {string} filepath - The path to the CSV file.
 * @param {Object} datasetInfo - Metadata about the dataset.
 * @returns {Promise<Object>} Parsed data with additional metadata.
 */
export const loadCSVData = async (filepath, datasetInfo) => {
  try {
	const response = await fetch(filepath);
	if (!response.ok) {
	  throw new Error(`HTTP error! status: ${response.status}`);
	}
	const csvText = await response.text();
	
	return new Promise((resolve, reject) => {
	  Papa.parse(csvText, {
		header: true,
		dynamicTyping: true,
		complete: (results) => {
		  const columns = results.meta.fields;
		  if (!columns || columns.length < 3) {
			reject(new Error(`Unexpected CSV format. Expected at least 3 columns, found ${columns.length}`));
			return;
		  }
		  const dataColumnName = columns[2]; // Assuming the data is in the 3rd column
		  const data = results.data
			.filter(row => row[columns[0]] && row[dataColumnName])
			.map(row => ({
			  name: row[columns[0]],
			  value: row[dataColumnName]
			}));
		  resolve({ 
			data, 
			unit: dataColumnName,
			date_collected: datasetInfo.date_collected,
			source: datasetInfo.source
		  });
		},
		error: (error) => {
		  reject(new Error(`Failed to parse CSV: ${error.message}`));
		},
	  });
	});
  } catch (error) {
	console.error(`Error loading CSV data from ${filepath}:`, error);
	throw new Error(`Failed to load CSV data from ${filepath}: ${error.message}`);
  }
};

/**
 * Formats a numerical value for display.
 * @param {number|string} value - The value to format.
 * @returns {string} Formatted value.
 */
export const formatDataValue = (value) => {
  if (typeof value === 'number') {
	return value.toLocaleString();
  }
  return value;
};

/**
 * Extracts a numeric value from a string.
 * @param {number|string} value - The value to extract from.
 * @returns {number} Numeric value.
 */
export const extractNumericValue = (value) => {
  if (typeof value === 'number') {
	return value;
  }
  if (typeof value === 'string') {
	const numericString = value.replace(/[^0-9.-]/g, '');
	return parseFloat(numericString);
  }
  return 0;
};