// src/App.js
import React, { useState, useEffect } from 'react';
import VisualizationSection from './components/VisualizationSection';
import { getDatasetList, loadCSVData } from './utils/datasetUtils';
import './App.css';

const App = () => {
  const [sections, setSections] = useState([{ id: 0 }]);
  const [csvData, setCSVData] = useState({});
  const [datasetOptions, setDatasetOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDatasetList = async () => {
      try {
        const datasets = await getDatasetList();
        console.log('Datasets received in App:', datasets);
        setDatasetOptions(datasets);
        
        if (datasets.length > 0) {
          const firstDataset = datasets[0];
          console.log('Loading first dataset:', firstDataset);
          const data = await loadCSVData(firstDataset.filepath, firstDataset);
          console.log('First dataset loaded:', data);
          setCSVData({ [firstDataset.value]: data });
        } else {
          console.warn('No datasets found in index.json');
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Error in fetchDatasetList:', error);
        setError(`Failed to load dataset list. Error: ${error.message}`);
        setLoading(false);
      }
    };

    fetchDatasetList();
  }, []);

  /**
   * Adds a new visualization section.
   */
  const addSection = () => {
    const newId = sections.length > 0 ? Math.max(...sections.map(s => s.id)) + 1 : 0;
    setSections([...sections, { id: newId }]);
  };

  /**
   * Removes a visualization section by ID.
   * @param {number} id - The ID of the section to remove.
   */
  const removeSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
  };

  /**
   * Loads a dataset by its filename.
   * @param {string} datasetFileName - The filename of the dataset to load.
   */
  const loadDataset = async (datasetFileName) => {
    if (!csvData[datasetFileName]) {
      try {
        console.log(`Loading dataset in App: ${datasetFileName}`);
        const datasetInfo = datasetOptions.find(d => d.value === datasetFileName);
        const data = await loadCSVData(datasetInfo.filepath, datasetInfo);
        console.log(`Dataset loaded in App:`, data);
        setCSVData(prevData => ({ ...prevData, [datasetFileName]: data }));
      } catch (error) {
        console.error(`Error loading dataset ${datasetFileName}:`, error);
        setError(`Failed to load dataset ${datasetFileName}. Error: ${error.message}`);
      }
    }
  };

  if (loading) {
    return <div className="loading"><p>Loading data...</p></div>;
  }

  if (error) {
    return (
      <div className="error">
        <h2>Error Loading Data</h2>
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Retry</button>
      </div>
    );
  }

  return (
    <div className="App">
     
      <div className="visualization-container">
        {sections.map((section) => (
          <VisualizationSection
            key={section.id}
            id={section.id}
            onRemove={removeSection}
            csvData={csvData}
            datasetOptions={datasetOptions}
            loadDataset={loadDataset}
          />
        ))}
      </div>
      <button className="add-section-button" onClick={addSection}>Add Section</button>
    </div>
  );
};

export default App;