// src/components/VisualizationSection.js
import React, { useState, useEffect } from 'react';
import DataCharts from './DataCharts';
import './VisualizationSection.css';

const VisualizationSection = ({ id, onRemove, csvData, datasetOptions, loadDataset }) => {
  // State for selected datasets
  const [selectedDatasets, setSelectedDatasets] = useState([datasetOptions[0]?.value || '']);
  
  // State to track if multiple selection is enabled
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  
  // States for search and filters
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filterSource, setFilterSource] = useState('');
  
  // State for chart data
  const [chartData, setChartData] = useState({});

  // Effect to load datasets when selectedDatasets or csvData changes
  useEffect(() => {
	selectedDatasets.forEach(dataset => {
	  if (dataset && !csvData[dataset]) {
		loadDataset(dataset);
	  }
	});
  }, [selectedDatasets, csvData, loadDataset]);

  // Effect to prepare chart data based on selected datasets
  useEffect(() => {
	const newChartData = {};
	selectedDatasets.forEach(dataset => {
	  if (csvData[dataset]) {
		const datasetInfo = datasetOptions.find(d => d.value === dataset);
		newChartData[dataset] = {
		  ...csvData[dataset],
		  date_collected: datasetInfo.date_collected,
		  source: datasetInfo.source,
		  filepath: datasetInfo.filepath
		};
	  }
	});
	setChartData(newChartData);
  }, [selectedDatasets, csvData, datasetOptions]);

  /**
   * Toggles the selection of a dataset based on the current selection mode.
   * @param {string} dataset - The filename of the dataset to toggle.
   */
  const handleDatasetToggle = (dataset) => {
	if (isMultiSelect) {
	  // Multiple Selection Mode
	  setSelectedDatasets(prev => 
		prev.includes(dataset) ? prev.filter(d => d !== dataset) : [...prev, dataset]
	  );
	} else {
	  // Single Selection Mode
	  setSelectedDatasets(prev => 
		prev.includes(dataset) ? [] : [dataset]
	  );
	}
  };

  /**
   * Handles search term changes.
   * @param {Object} e - Event object.
   */
  const handleSearchChange = (e) => {
	setSearchTerm(e.target.value);
  };

  /**
   * Handles filter changes.
   * @param {Object} e - Event object.
   */
  const handleFilterChange = (e) => {
	const { name, value } = e.target;
	switch(name) {
	  case 'category':
		setFilterCategory(value);
		break;
	  case 'date':
		setFilterDate(value);
		break;
	  case 'source':
		setFilterSource(value);
		break;
	  default:
		break;
	}
  };

  /**
   * Toggles the selection mode between single and multiple.
   */
  const toggleSelectionMode = () => {
	setIsMultiSelect(prev => {
	  const newMode = !prev;
	  if (!newMode && selectedDatasets.length > 1) {
		// If switching to single selection, keep only the first selected dataset
		setSelectedDatasets([selectedDatasets[0]]);
	  }
	  return newMode;
	});
  };

  /**
   * Applies search and filters to dataset options.
   * @returns {Array} Filtered and sorted dataset options.
   */
  const getFilteredDatasets = () => {
	return datasetOptions
	  .filter(dataset => {
		const matchesSearch = dataset.label.toLowerCase().includes(searchTerm.toLowerCase());
		const matchesCategory = filterCategory ? dataset.category === filterCategory : true;
		const matchesDate = filterDate ? dataset.date_collected === filterDate : true;
		const matchesSource = filterSource ? dataset.source === filterSource : true;
		return matchesSearch && matchesCategory && matchesDate && matchesSource;
	  })
	  .sort((a, b) => {
		// Sort categories alphabetically
		if (a.category < b.category) return -1;
		if (a.category > b.category) return 1;
		// If same category, sort datasets alphabetically
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	  });
  };

  // Get unique categories, dates, and sources for filter options
  const uniqueCategories = Array.from(new Set(datasetOptions.map(d => d.category))).sort();
  const uniqueDates = Array.from(new Set(datasetOptions.map(d => d.date_collected))).sort();
  const uniqueSources = Array.from(new Set(datasetOptions.map(d => d.source))).sort();

  // Group filtered datasets by category
  const filteredDatasets = getFilteredDatasets();
  const datasetsByCategory = filteredDatasets.reduce((acc, dataset) => {
	const category = dataset.category || 'Uncategorized';
	if (!acc[category]) {
	  acc[category] = [];
	}
	acc[category].push(dataset);
	return acc;
  }, {});

  // Extract unique sources and dates from selected datasets
  const uniqueSelectedSources = Array.from(new Set(
	selectedDatasets.map(dataset => {
	  const info = datasetOptions.find(d => d.value === dataset);
	  return info?.source || 'Unknown';
	})
  ));
  
  const uniqueSelectedDates = Array.from(new Set(
	selectedDatasets.map(dataset => {
	  const info = datasetOptions.find(d => d.value === dataset);
	  return info?.date_collected || 'Unknown';
	})
  ));

  return (
	<div className="visualization-section">
	  

	  {/* Search and Filters */}
	  <div className="search-filter-container">
		<input 
		  type="text" 
		  placeholder="🔍 Search datasets..." 
		  value={searchTerm} 
		  onChange={handleSearchChange}
		  className="search-bar"
		  aria-label="Search datasets"
		/>
		<select 
		  name="category" 
		  value={filterCategory} 
		  onChange={handleFilterChange} 
		  className="filter-dropdown"
		  aria-label="Filter by category"
		>
		  <option value="">All Categories</option>
		  {uniqueCategories.map(category => (
			<option key={category} value={category}>{category}</option>
		  ))}
		</select>
		<select 
		  name="date" 
		  value={filterDate} 
		  onChange={handleFilterChange} 
		  className="filter-dropdown"
		  aria-label="Filter by date collected"
		>
		  <option value="">All Years</option>
		  {uniqueDates.map(date => (
			<option key={date} value={date}>{date}</option>
		  ))}
		</select>
		<select 
		  name="source" 
		  value={filterSource} 
		  onChange={handleFilterChange} 
		  className="filter-dropdown"
		  aria-label="Filter by source"
		>
		  <option value="">All Sources</option>
		  {uniqueSources.map(source => (
			<option key={source} value={source}>{source}</option>
		  ))}
		</select>
		<button 
		  className="toggle-select-button" 
		  onClick={toggleSelectionMode}
		  aria-pressed={isMultiSelect}
		>
		  {isMultiSelect ? 'Disable Multi-select' : 'Enable Multi-select'}
		</button>
	  </div>

	  {/* Dataset Selector in Scrollable Container */}
	  <div className="dataset-selector scrollable-container">
		{Object.entries(datasetsByCategory).map(([category, datasets]) => (
		  <div key={category} className="dataset-category">
			<h5>{category}</h5>
			<div className="dataset-options-horizontal">
			  {datasets.map((option) => (
				<label key={option.value} className="dataset-option">
				  <input
					type="checkbox"
					value={option.value}
					checked={selectedDatasets.includes(option.value)}
					onChange={() => handleDatasetToggle(option.value)}
					aria-label={`Select dataset ${option.label}`}
				  />
				  {option.label}
				</label>
			  ))}
			</div>
		  </div>
		))}
		{filteredDatasets.length === 0 && (
		  <p className="no-datasets">No datasets match your search and filter criteria.</p>
		)}
	  </div>

	  {Object.keys(chartData).length > 0 ? (
		<DataCharts chartData={chartData} />
	  ) : (
		<p>Loading datasets...</p>
	  )}
	  <div className="section-header">
		  
		  <button 
			className="remove-section-button" 
			onClick={() => onRemove(id)}
			aria-label={`Remove visualization section ${id}`}
		  >
			Remove Section
		  </button>
		</div>
	</div>
  );
};

export default VisualizationSection;